<template>
    <div>
        <v-toolbar flat dense color="greyRaised">
          <v-spacer></v-spacer>
          <v-chip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  id="add-relationship-button"
                  small color="primary"
                  icon
                  v-on="on"
                  @click="addProductCategory()"
                >
                  <v-icon small>add_circle</v-icon>
                </v-btn>
              </template>
              Add Category
            </v-tooltip>
            <!-- <v-btn
              small
              icon
              id="filter-relationship-button"
              @click="filterDialog = !filterDialog"
            >
              <v-icon small>filter_alt</v-icon>
            </v-btn> -->
            <v-text-field
              id="search-relationship"
              placeholder="Search"
              class="mb-1"
              hide-details
              rounded
              clearable
              dense
              style="width: 300px"
              v-model="search"
            ></v-text-field>
          </v-chip>
        </v-toolbar>
        <v-data-table
        :headers="headers"
        :loading="loading"
        :items="categories"
        dense
        disable-default-footer
        disable-pagination
        >
        <template v-slot:[`item.actions`]="{ item }">
        <v-row justify="center">
          <v-btn icon small>
            <v-icon small @click="editItem(item)">edit</v-icon>
          </v-btn>
          <v-btn icon small color="danger" @click="deleteItem(item)">
            <v-icon small>delete</v-icon>
          </v-btn>
        </v-row>
      </template>
        </v-data-table>

        <v-dialog v-model="categoryModal" width="800px" persistent>
            <v-card>
                <v-toolbar flat dense>
                    <v-toolbar-title>Product Category</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="primary" @click="saveCategory" :loading="savingCategory" :disabled="!category.name">
                        <v-icon small>save</v-icon>
                    </v-btn>
                    <v-btn icon color="redPop" @click="categoryModal = false">
                        <v-icon small>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <v-text-field label="Name" v-model="category.name" dense outlined></v-text-field>
                            <v-textarea label="Description" v-model="category.description" rows="5" dense outlined></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-list dense subheader v-if="category.productServiceSubcategories">
                                <v-subheader style="font-size: 14px">Subcategories
                                    <v-btn icon color="primary" @click="addSubcategory()">
                                        <v-icon small>
                                            add_circle_outline
                                        </v-icon>
                                    </v-btn>

                                </v-subheader>
                                <v-divider></v-divider>
                                <v-list-item v-for="(sub, index) in category.productServiceSubcategories" :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">{{ sub.name }}</v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 11px" v-if="sub.description">{{ sub.description }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-row justify="center">
                                            <v-btn icon @click="addSubcategory(sub, index)">
                                                <v-icon small>edit</v-icon>
                                            </v-btn>
                                            <v-btn icon color="red" @click="deleteSubcategory(sub, index)">
                                                <v-icon small>delete</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-if="category.productServiceSubcategories.length == 0">
                                    <v-list-item-content class="text-center">
                                        <span style="font-size: 12px; color: grey">
                                            No subcategories
                                        </span>
                                        </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="subcategoryModal" width="400px" persistent>
            <v-card>
                <v-toolbar flat dense>
                    <v-toolbar-title>Product Subategory</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="primary" @click="saveSubcategory" :loading="savingSubcategory" :disabled="!subcategory.name">
                        <v-icon small>save</v-icon>
                    </v-btn>
                    <v-btn icon color="redPop" @click="subcategoryModal = false">
                        <v-icon small>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field label="Name" v-model="subcategory.name" dense outlined></v-text-field>
                    <v-textarea label="Description" v-model="subcategory.description" rows="5" dense outlined></v-textarea>
                </v-card-text>
            </v-card>
        </v-dialog>


    </div>
</template>
<script>
export default {
    data: ()=>({
        category: {},
        categories: [],
        categoryModal: false,
        headers: [
            {
                text: 'Actions',
                value: 'actions',
                align: 'center',
                sortable: false
            },
            {
                text: 'Name',
                value: 'name'
            },
            {
                text: 'Description',
                value: 'description'
            }
        ],
        savingCategory: false,
        search: null,
        subcategory: {},
        subcategoryModal: false,
        savingSubcategory: false
    }),
    created(){
        this.getCategories()
    },
    methods: {
        addProductCategory(item = {productServiceSubcategories: []}){
            this.category = item
            this.categoryModal = true
        },
        addSubcategory(item = {}, index = null){
                this.subcategory = item
                this.subcategory.index = index
                if(this.category.id){
                    this.subcategory.productServiceCategoryId = this.category.id
                }
                this.subcategoryModal = true
        },
        deleteItem(item) {
      this.$confirm(
        "Are you sure you want to delete this product?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (item.id) {
            await this.$API.updateProductCategory({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.getCategories()
          this.$message.success("Successfully deleted product: " + item.name);
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    editItem(item) {
        this.category = item
        this.categoryModal = true    
    },
        async deleteSubcategory(item, index){
            if(item.id){
                await this.$API.updateProductSubcategory({
                    id: item.id,
                    isDeleted: true,
                    isActive: false,
                })
            }
            this.category.productServiceSubcategories.splice(index, 1)
            this.$message.success("Successfully deleted subcategory: " + item.name)
        },
        async getCategories(){
            this.loading = true
            this.categories = await this.$API.getProductCategories()
            this.loading = false
        },
        async saveCategory(){
            this.savingCategory = true
            if(this.category.id){
                await this.$API.updateProductCategory(this.category)
            } else {
                await this.$API.createProductCategory(this.category)
            }
            this.savingCategory = false
            this.getCategories()
            this.categoryModal = false
        },
        async saveSubcategory(){
            this.savingSubcategory = true
            if(this.category.id){
                if(this.subcategory.id){
                await this.$API.updateProductSubcategory(this.subcategory)
            } else {
                let result = await this.$API.createProductSubcategory(this.subcategory)
                if(this.subcategory.index !== null){
                    this.category.productServiceSubcategories[this.subcategory.index] = result
                } else {
                    this.category.productServiceSubcategories.push(result)
                }
            }  
            } else {
                if(this.subcategory.index !== null){
                    this.category.productServiceSubcategories[this.subcategory.index] = this.subcategory
                } else {
                    this.category.productServiceSubcategories.push(this.subcategory)
                }
            }
           
            this.savingSubcategory = false
            this.subcategoryModal = false
        }
    }
}
</script>