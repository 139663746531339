<template>
    <div>
        <v-tabs grow v-model="tab">
            <v-tab key="articles" @click="activeTab != 'news/articles' ? $router.push({ hash: '#news/articles' }) : ''">Articles</v-tab>
            <v-tab key="authors" @click="activeTab != 'news/authors' ? $router.push({ hash: '#news/authors' }) : ''">Authors</v-tab>
            <v-tab key="logs" @click="activeTab != 'news/logs' ? $router.push({ hash: '#news/logs' }) : ''">Logs</v-tab>

            <v-tab-item key="articles">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                <v-card flat>
                                    <v-card-text>
                                        <v-row justify="end" class="mb-2">
                                            <v-col cols="12" sm="4" md="4" lg="4">
                                                <el-input id="articleSearch" suffix-icon="el-icon-search"
                                                    v-model="articleSearch" clearable placeholder="Search">
                                                    <template slot="prepend">
                                                        <el-button @click="createArticleDialog = !createArticleDialog"><i
                                                                class="el-icon-plus"></i></el-button>
                                                    </template>
                                                    <template slot="append">
                                                        <el-button @click="filterDialog = !filterDialog"
                                                            el-tooltip="Advanced Filter">
                                                            <v-icon small>filter_alt</v-icon>
                                                        </el-button>
                                                    </template>
                                                </el-input>
                                            </v-col>
                                        </v-row>
                                        <v-data-table :loading="articleLoading" dense :key="updateKey"
                                            :items="computedArticles" hide-default-footer :headers="articleHeaders"
                                            :options="{
                                                page: articlePage,
                                                itemsPerPage: articleParams.limit,
                                                pageStart: 1,
                                            }">
                                            <template v-slot:item.actions="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn icon @click="readArticle(item)" v-on="on">
                                                            <v-icon color="info">feed</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Read Article</span>
                                                </v-tooltip>

                                                <!-- Add v-tooltip for the second button -->
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="setPublishStatus(item)" icon v-on="on">
                                                            <v-icon v-if="item.isActive === false"
                                                                color="success">publish</v-icon>
                                                            <v-icon style="transform: rotate(180deg)" v-else
                                                                color="primary">publish</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ item.isActive ? 'Unpublish' : 'Publish' }} Article</span>
                                                </v-tooltip>

                                                <!-- Add v-tooltip for the third button -->
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn icon @click="confirmDeletion(item)" v-on="on">
                                                            <v-icon color="primary">delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Delete Article</span>
                                                </v-tooltip>
                                            </template>
                                            <template v-slot:item.region="{ item }">
                                                <v-select v-model="item.region" :items="regions"
                                                    @change="onRegionChange(item)"></v-select>
                                            </template>
                                            <template v-slot:item.isActive="{ item }">
                                                <v-icon v-if="item.isActive === true" outlined color="success">
                                                    check_circle
                                                </v-icon>
                                                <v-icon v-else outlined color="primary">
                                                    cancel
                                                </v-icon>
                                            </template>
                                            <template v-slot:item.createdAt="{ item }">
                                                {{ new
                                                    Date(item.createdAt).toLocaleString('en-ZA', {
                                                        timeZone: 'Africa/Johannesburg',
                                                        dateStyle: 'medium',
                                                        timeStyle: 'medium'
                                                    }) }}
                                            </template>
                                        </v-data-table>
                                        <v-row justify="center" class="mb-2">
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                <el-pagination style="color: var(--v-primaryText-base)"
                                                    :current-page.sync="articlePage" :page-size.sync="articleParams.limit"
                                                    :page-sizes="[5, 10, 15]"
                                                    layout="sizes, prev, pager, next, jumper, total" :total="articleTotal"
                                                    class="text-center"></el-pagination>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item key="authors">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                <v-card flat>
                                    <v-card-text>
                                        <v-row justify="end" class="mb-2">
                                            <v-col cols="12" sm="4" md="4" lg="4">
                                                <el-input id="authorSearch" suffix-icon="el-icon-search"
                                                    v-model="authorSearch" clearable placeholder="Search">
                                                    <template slot="prepend">
                                                        <el-button @click="createAuthorDialog = !createAuthorDialog"><i
                                                                class="el-icon-plus"></i></el-button>
                                                    </template>
                                                </el-input>
                                            </v-col>
                                        </v-row>
                                        <v-data-table :loading="authorsLoading" dense :key="updateKey"
                                            :items="computedAuthors" hide-default-footer :headers="authorHeaders" :options="{
                                                page: authorPage,
                                                itemsPerPage: authorParams.limit,
                                                pageStart: 1,
                                            }">
                                            <template v-slot:item.actions="{ item }">
                                                <v-btn @click="startAuthorEdit(item)" icon>
                                                    <v-icon color="info">edit</v-icon>
                                                </v-btn>
                                                <v-btn @click="startAuthorDeletion(item)" icon>
                                                    <v-icon color="primary">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:item.personality="{ item }">
                                                <td style="max-width: 1000px; overflow: hidden;">
                                                    {{ item.personality }}
                                                </td>
                                            </template>
                                            <template v-slot:item.regions="{ item }">
                                                <v-chip outlined :key="index"
                                                    v-for="(author, index) of JSON.parse(item.regions)">
                                                    {{ author }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item.createdAt="{ item }">
                                                {{ new
                                                    Date(item.createdAt).toLocaleString('en-ZA', {
                                                        timeZone: 'Africa/Johannesburg',
                                                        dateStyle: 'medium',
                                                        timeStyle: 'medium'
                                                    }) }}
                                            </template>
                                        </v-data-table>
                                        <v-row justify="center" class="mb-2">
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                <el-pagination style="color: var(--v-primaryText-base)"
                                                    :current-page.sync="authorPage" :page-size.sync="authorParams.limit"
                                                    :page-sizes="[5, 10, 15]"
                                                    layout="sizes, prev, pager, next, jumper, total" :total="authorTotal"
                                                    class="text-center"></el-pagination>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item key="logs">

                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                <v-card flat>
                                    <v-card-text>
                                        <v-row justify="end" class="mb-2">
                                            <v-col cols="12" sm="4" md="4" lg="4">
                                                <el-input id="logsSearch" v-model="logsSearch" suffix-icon="el-icon-search" clearable
                                                    placeholder="Search">
                                                    <template slot="prepend">
                                                        <el-button><i class="el-icon-plus"></i></el-button>
                                                    </template>
                                                </el-input>
                                            </v-col>
                                        </v-row>
                                        <v-data-table :loading="logsLoading" dense :key="updateKey" :items="computedLogs"
                                            hide-default-footer :headers="logsHeaders" :options="{
                                                page: logsPage,
                                                itemsPerPage: logsParams.limit,
                                                pageStart: 1,
                                            }" @click:row="readLog">
                                        </v-data-table>
                                        <v-row justify="center" class="mb-2">
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="logsPage" :page-size.sync="logsParams.limit" :page-sizes="[5, 10, 15]" layout="sizes, prev, pager, next, jumper, total" :total="logsTotal" class="text-center"></el-pagination>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>

        <v-dialog persistent v-model="readLogDialog" max-width="1000">
            <v-card flat height="800" style="overflow-y: hidden">
                <v-toolbar flat dark>
                    <v-toolbar-title>Read Log</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="readLogDialog = !readLogDialog">
                        <v-icon color="primary">close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <vue-json-pretty height="725" :data="logJSON" :showLine="false" :virtual="true" :showIcon="true"/>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="createArticleDialog" max-width="850">
            <v-card flat height="925" style="overflow-y: hidden">
                <v-toolbar flat dark>
                    <v-toolbar-title>Generate New AI-Article</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn :loading="articleGenLoad" icon @click="createArticle()">
                        <v-icon color="success">check</v-icon>
                    </v-btn>
                    <v-btn :disabled="articleGenLoad" icon @click="createArticleDialog = !createArticleDialog">
                        <v-icon color="primary">close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-subheader>Author Details</v-subheader>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-autocomplete :items="authors" v-model="selectedAuthor" item-text="journalist"
                                        item-value="journalist" return-object label="Author"></v-autocomplete>
                                </v-col>
                                <v-col>
                                    <v-select v-model="selectedAuthor.region" :items="regions" outlined
                                        label="Author's Regions"></v-select>
                                </v-col>
                            </v-row>
                            <v-textarea v-model="selectedAuthor.personality" readonly outlined label="Author's Personality"
                                no-resize rows="5"></v-textarea>
                        </v-container>
                        <v-divider></v-divider>
                        <v-subheader>Article Details</v-subheader>
                        <v-container>
                            <v-textarea v-model="articleBody" outlined label="Article's Body" no-resize
                                rows="5"></v-textarea>
                        </v-container>
                        <v-divider></v-divider>
                        <v-subheader>Citations/Resources</v-subheader>
                        <v-container>
                            <v-list style="max-height: 200px; overflow-y: auto;">
                                <v-list-item v-for="(item, index) in resourceCitations" :key="index">
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col>
                                                <v-text-field v-model="item.sourceURL" :readonly="!item.editing"
                                                    label="Source URL"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-btn @click="toggleEdit(index)" v-if="!item.editing" icon>
                                            <v-icon color="info">edit</v-icon>
                                        </v-btn>
                                        <v-btn @click="saveEdit(index)" v-if="item.editing" icon>
                                            <v-icon color="success">save</v-icon>
                                        </v-btn>
                                        <v-btn @click="deleteItem(index)" icon>
                                            <v-icon color="primary">delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-layout align-center justify-center>
                                    <v-btn @click="addItem()" icon>
                                        <v-icon color="success">add</v-icon>
                                    </v-btn>
                                </v-layout>
                            </v-list>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="regenArticleDialog" max-width="850">
            <v-card flat height="925" style="overflow-y: hidden">
                <v-toolbar flat dark>
                    <v-toolbar-title>Regenerate AI-Article</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn :loading="regenArticleLoad" icon @click="regenArticle()">
                        <v-icon color="success">check</v-icon>
                    </v-btn>
                    <v-btn :disabled="regenArticleLoad" icon @click="regenArticleDialog = !regenArticleDialog">
                        <v-icon color="primary">close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-subheader>Author Details</v-subheader>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-autocomplete :items="authors" v-model="selectedAuthor" item-text="journalist"
                                        item-value="journalist" return-object label="Author"></v-autocomplete>
                                </v-col>
                                <v-col>
                                    <v-select v-model="selectedAuthor.region" :items="regions" outlined
                                        label="Author's Regions"></v-select>
                                </v-col>
                            </v-row>
                            <v-textarea v-model="selectedAuthor.personality" readonly outlined label="Author's Personality"
                                no-resize rows="5"></v-textarea>
                        </v-container>
                        <v-divider></v-divider>
                        <v-subheader>Article Details</v-subheader>
                        <v-container>
                            <v-textarea v-model="articleBody" outlined label="Article's Body" no-resize
                                rows="5"></v-textarea>
                        </v-container>
                        <v-divider></v-divider>
                        <v-subheader>Citations/Resources</v-subheader>
                        <v-container>
                            <v-list style="max-height: 200px; overflow-y: auto;">
                                <v-list-item v-for="(item, index) in resourceCitations" :key="index">
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col>
                                                <v-text-field v-model="item.sourceURL" :readonly="!item.editing"
                                                    label="Source URL"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-btn @click="toggleEdit(index)" v-if="!item.editing" icon>
                                            <v-icon color="info">edit</v-icon>
                                        </v-btn>
                                        <v-btn @click="saveEdit(index)" v-if="item.editing" icon>
                                            <v-icon color="success">save</v-icon>
                                        </v-btn>
                                        <v-btn @click="deleteItem(index)" icon>
                                            <v-icon color="primary">delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-layout align-center justify-center>
                                    <v-btn @click="addItem()" icon>
                                        <v-icon color="success">add</v-icon>
                                    </v-btn>
                                </v-layout>
                            </v-list>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="createAuthorDialog" max-width="850">
            <v-card flat style="overflow-y: hidden">
                <v-toolbar flat dark>
                    <v-toolbar-title>Create New Author</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="addAuthor()" icon>
                        <v-icon color="success">check</v-icon>
                    </v-btn>
                    <v-btn icon @click="createAuthorDialog = !createAuthorDialog">
                        <v-icon color="primary">close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="newAuthor.journalist" label="Journalist Name"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select :items="regions" v-model="newAuthor.regions" multiple label="Regions"></v-select>
                            </v-col>
                        </v-row>
                        <v-textarea outlined v-model="newAuthor.personality" label="Personality" no-resize
                            rows="5"></v-textarea>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="editAuthorDialog" max-width="850">
            <v-card flat style="overflow-y: hidden">
                <v-toolbar flat dark>
                    <v-toolbar-title>Edit Author</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="saveAuthorEdit()">
                        <v-icon color="success">edit</v-icon>
                    </v-btn>
                    <v-btn icon @click="cancelAuthorEdit()">
                        <v-icon color="primary">close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="editAuthor.journalist" label="Journalist Name"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select :items="regions" v-model="editAuthor.newRegions" multiple
                                    label="Regions"></v-select>
                            </v-col>
                        </v-row>
                        <v-textarea outlined v-model="editAuthor.personality" label="Personality" no-resize
                            rows="5"></v-textarea>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteAuthorDialog" class="black-dialog" width="450px" persistent>
            <v-card width="auto">
                <v-toolbar>
                    <v-toolbar-title>
                        Delete "{{ authorToDelete.journalist }}"?
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteAuthor()" icon>
                        <v-icon color="success">check</v-icon>
                    </v-btn>
                    <v-btn @click="deleteAuthorDialog = false" icon>
                        <v-icon color="primary">close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    Confirm deletion of this author? Once deleted, it can not be recovered.
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="articleDialog" width="40vw">
            <v-card height="auto" class="pa-0">
                <v-toolbar flat>
                    <v-toolbar-title>
                        <h2>Article Review</h2>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="loadImageDialog(selectedArticle)" icon v-on="on">
                                <v-icon color="info">image</v-icon>
                            </v-btn>
                        </template>
                        <span>Change Image</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="beginArticleRegen(selectedArticle)" icon v-on="on">
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Article</span>
                    </v-tooltip>
                </v-toolbar>
                <div style="height: 30vh; width: 100%"
                    :style="selectedArticle.largeImage ? { 'background-image': `url(${selectedArticle.largeImage} !important`, 'background-size': 'cover' } : ''">
                    <div
                        style="height: 100%; width: 100%;background-image: linear-gradient(to bottom, #3d3d3d57, #3d3d3d); ">
                        <div style="position: absolute; width: 70%; left: 3vw">
                            <h1 v-if="selectedArticle.Title">{{ selectedArticle.Title }}</h1>
                            <span>
                                <v-icon>account_circle</v-icon>{{ selectedArticle.Author ?? '' }}
                            </span>
                            <br>
                            <span>
                                <v-icon>schedule</v-icon>{{ formatPublicationDate(selectedArticle.createdAt) }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="px-7">

                    <v-card-text v-if="selectedArticle.Body" style="white-space: pre-line"
                        v-html="markdownToHtml(selectedArticle.Body)">
                    </v-card-text>
                    <div class="px-1">
                        Tags:
                        <v-chip class="ma-1" :key="index" color="primary" outlined
                            v-for="(item, index) in selectedArticle.Tags">
                            {{ item }}
                        </v-chip>

                        <v-chip class="ma-1" :key="index" color="primary" outlined
                            v-for="(item, index) in selectedArticle.Regions">
                            {{ item }}
                        </v-chip>
                    </div>
                    <div class="px-1">
                        <v-list>
                            <v-list-group v-if="selectedArticle.Citations" no-action style="padding-left: 0;">
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title class="headline">Sources</v-list-item-title>
                                    </v-list-item-content>
                                </template>

                                <v-list-item-group style="padding-left: 0;">
                                    <v-list-item v-for="(citation, index) in selectedArticle.Citations" :key="index">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 12px; white-space: normal !important;">{{
                                                citation.Title }}.<br> {{ citation["Source URL"] }}: ({{ citation.Source
    }})</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list-group>

                            <v-alert v-else :value="true" type="info">No Sources available</v-alert>
                        </v-list>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="imageDialog" width="80vw">
            <v-card height="auto" class="pa-0">
                <v-toolbar flat>
                    <v-toolbar-title>
                        Regenerate Image?
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="replaceImage()" icon>
                        <v-icon color="success">check</v-icon>
                    </v-btn>
                    <v-btn icon @click="imageDialog = !imageDialog">
                        <v-icon color="primary">close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col class="d-inline-block">
                                <v-card>
                                    <v-toolbar flat>
                                        <v-toolbar-title>
                                            Current Image
                                        </v-toolbar-title>
                                    </v-toolbar>
                                    <v-img style="height: 100%; width: 100%" :src="selectedArticle.mediumImage"></v-img>
                                </v-card>
                            </v-col>
                            <v-col class="d-inline-block">
                                <v-card>
                                    <v-toolbar flat>
                                        <v-toolbar-title>
                                            New Image
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn :loading="regenImageLoad" icon @click="regenImage()">
                                            <v-icon>refresh</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-img :key="updateKey" style="height: 100%; width: 100%" :src="previewImage"></v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteFileDialog" class="black-dialog" width="1000px" persistent>
            <v-card width="auto">
                <v-toolbar>
                    <v-toolbar-title>
                        Delete "{{ selectedArticle.fileName }}"?
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteArticleFile()" icon>
                        <v-icon color="success">check</v-icon>
                    </v-btn>
                    <v-btn @click="deleteFileDialog = false" icon>
                        <v-icon color="primary">close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    Confirm deletion of this article? Once deleted, it can not be recovered on the storage.
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="filterDialog" persistent max-width="600px">
            <v-card dark :loading="loading">
                <v-toolbar>
                    <v-card-title>
                        <span class="text-h5">Advanced filter</span>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" plain depressed elevation="0" :disabled="!filterActive"
                                @click="clearFilterDialog">
                                <v-icon>filter_alt_off</v-icon>
                            </v-btn>
                        </template>
                        <span>Clear Filters</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" plain depressed elevation="0" @click="filterSearch">
                                <v-icon>filter_alt</v-icon>
                            </v-btn>
                        </template>
                        <span>Apply Filters</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" plain depressed elevation="0" @click="closeFilterDialog()">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </template>
                        <span>Close</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-select clearable :items="authorNames" v-model="filters.Author" label="Authors"></v-select>
                        </v-row>
                        <v-row>
                            <v-select clearable :items="regions" v-model="filters.Region" label="Regions"></v-select>
                        </v-row>
                        <v-row>
                            <v-select clearable :items="computedTags" v-model="filters.MainTag" label="Main Tag"></v-select>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch :disabled="filters.Unpublished === true" v-model="filters.Published"
                                    label="Published"></v-switch>
                            </v-col>
                            <v-col>
                                <v-switch :disabled="filters.Published === true" v-model="filters.Unpublished"
                                    label="Unpublished"></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color">{{ snackbar.text
        }}</v-snackbar>
    </div>
</template>

<script>
export default {
    data: () => ({
        articleGenLoad: false,
        filters: {},
        filterDialog: false,
        filterActive: false,
        authorsLoading: false,
        loading: false,
        tags: [
            "Logistics Trends",
            "Technology",
            "Global Trade",
            "Economics",
            "Agriculture",
            "Business",
            "Financial Markets",
            "Transportation",
            "Government & Regulations",
            "Sustainability",
            "Emerging Markets",
            "Global Ports",
            "Other"
        ],
        regions: [
            "-middle-east",
            "europe",
            "asia",
            "asia-and-oceania",
            "oceania",
            "africa",
            "latin-america",
            "south-america",
            "north-america",
            "america",
            "unknown"
        ],
        snackbar: {
            show: false,
            text: "",
            timeout: 5000,
            color: "error"
        },
        newAuthor: {
            journalist: "",
            regions: [],
            personality: "You're {{ Name Here }}, you are a {{ Role at LogLive News Here }}, {{ Rest of Personality}}."
        },
        createAuthorDialog: false,
        authors: [],
        authorPage: 1,
        authorTotal: 100,
        authorParams: {
            limit: 15,
            offset: 0
        },
        authorSearch: "",
        authorHeaders: [{
            text: "Actions",
            value: "actions"
        },
        {
            text: "Journalist",
            value: "journalist",
        },
        {
            text: "Personality",
            value: "personality",
        },
        {
            text: "Regions",
            value: "regions",
        },
        {
            text: "Creation Date",
            value: "createdAt"
        }
        ],
        logs: [],
        logsLoading: false,
        logsPage: 1,
        logsTotal: 100,
        logsParams: {
            limit: 15,
            offset: 0,
            page: 1
        },
        logsSearch: "",
        logsHeaders: [
            {
                text: "File Name",
                value: "fileName",
            },
            {

                text: "File Type",
                value: "fileType",
            },
            {

                text: "Last Modified",
                value: "lastModified",
            },
            {

                text: "Size",
                value: "size",
            },
        ],
        logJSON: {},
        readLogDialog: false,
        tab: "articles",
        resourceCitations: [{
            sourceURL: '',
            editing: false
        },],
        createArticleDialog: false,
        regenArticleDialog: false,
        articles: [],
        articlePage: 1,
        articleTotal: 100,
        articleParams: {
            limit: 15,
            offset: 0,
            page: 1
        },
        articleSearch: "",
        articleHeaders: [{
            text: "Actions",
            value: "actions",
            align: "center",
        },
        {
            text: "Article's File",
            value: "fileName"
        },
        {
            text: "Title",
            value: "articleName",
        },
        {
            text: "Author",
            value: "author",
        },
        {
            text: "Main Tag",
            value: "mainTag",
        },
        {
            text: "Region",
            value: "region",
        },
        {
            text: "Published",
            value: "isActive",
            align: "center",
        },
        {
            text: "Creation Date",
            value: "createdAt"
        }
        ],
        updateKey: 0,
        articleLoading: false,
        editAuthor: {}, // Separate object for editing state
        originalAuthor: {}, // Object to store the original data before editing
        editAuthorDialog: false,
        deleteAuthorDialog: false,
        authorToDelete: {},
        selectedArticle: {},
        articleDialog: false,
        selectedAuthor: {},
        articleBody: "",
        imageDialog: false,
        deleteFileDialog: false,
        newImages: {},
        previewImage: null,
        previewLoad: false,
        regenImageLoad: false,
        regenArticleLoad: false
    }),
    watch: {
        'selectedAuthor': {
            handler(newVal) {
                // Check if 'regions' property exists and is a string
                if (newVal.regions && typeof newVal.regions === 'string') {
                    try {
                        // Attempt to parse 'regions' as JSON
                        newVal.regions = JSON.parse(newVal.regions);
                    } catch (error) {
                        console.error('Error parsing JSON for regions:', error);
                        // Handle parsing error if needed
                    }
                }
            },
            deep: true // Watch for changes deeply within the object
        },
        articlePage: {
            handler: async function () {
                this.articleParams.offset = (this.articlePage - 1) * this.articleParams.limit;
                // console.log({
                //     page: this.articlePage
                // })
                await this.allArticles();
                this.updateKey++;
            },
            immediate: true
        },
        'articleParams.limit': {
            handler: async function () {
                this.articleParams.offset = (this.articlePage - 1) * this.articleParams.limit;
                // console.log({
                //     page: this.articlePage
                // })
                await this.allArticles();
                this.updateKey++;
            },
            immediate: true
        },
        authorPage: {
            handler: async function () {
                this.authorParams.offset = (this.authorPage - 1) * this.authorParams.limit;
                await this.fetchAuthors(this.authorParams);
                this.updateKey++;
            },
            immediate: true
        },
        'authorParams.limit': {
            handler: async function () {
                this.authorParams.offset = (this.authorPage - 1) * this.authorParams.limit;
                await this.fetchAuthors(this.authorParams);
                this.updateKey++;
            },
            immediate: true
        },
        articleSearch: {
            handler: function () {
                setTimeout(async () => {
                    await this.allArticles()
                }, 2000);
            },
            immediate: true
        },
        authorSearch: {
            handler: function () {
                setTimeout(async () => {
                    await this.fetchAuthors()
                }, 2000);
            },
            immediate: true
        },
        logsPage: {
            handler: async function () {
                this.logsParams.offset = (this.logsPage - 1) * this.logsParams.limit;
                await this.fetchLogs(this.logsParams);
                this.updateKey++;
            },
            immediate: true
        },
        'logsParams.limit': {
            handler: async function () {
                this.logsParams.offset = (this.logsPage - 1) * this.logsParams.limit;
                await this.fetchLogs(this.logsParams);
                this.updateKey++;
            },
            immediate: true
        },
        logsSearch: {
            handler: function () {
                setTimeout(async () => {
                    await this.fetchLogs(this.logsParams)
                }, 2000);
            },
            immediate: true
        },
    },
    computed: {
        computedLogs() {
            let result = this.logs
            return result
        },
        computedAuthors() {
            let result = this.authors;
            return result
        },
        computedArticles() {
            let result = this.articles;
            return result
        },
        computedTags() {
            let result = this.tags;
            return result
        },
        authorNames() {
            let authors = this.computedAuthors;
            let result = authors.map(obj => {
                let journalist = obj.journalist.replace(/_/g, ' ');
                journalist = journalist.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                return journalist;
            });
            return result;
        },

    },
    methods: {
        async filterSearch() {
            if (this.filters) {
                this.articleParams.filters = this.filters
            }

            this.allArticles();
            this.closeFilterDialog();
            this.filterActive = true
        },
        async clearFilterDialog() {
            this.filters = {}
            this.filterActive = false
            delete this.articleParams.filters
            this.closeFilterDialog()
        },
        async closeFilterDialog() {
            this.filterDialog = false
            this.allArticles();
        },
        async selectMainTag(item) {
            this.filters.MainTag = item;
            // console.log({
            //     Filters: this.filters
            // });
        },
        async deleteArticleFile() {
            let response = await this.$API.deleteArticleFile(this.selectedArticle);

            this.articles = this.articles.filter(article => article !== this.selectedArticle);

            this.deleteFileDialog = false;
            this.updateKey++;
        },
        async confirmDeletion(item) {
            this.selectedArticle = item
            this.deleteFileDialog = true
        },
        async loadImageDialog(item) {
            // console.log({item})
            this.articleDialog = false
            this.selectedArticle = item
            this.regenImage()
            this.imageDialog = true;
            // console.log(this.selectedArticle)
        },
        formatPublicationDate(dateString) {
            const options = {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
                timeZone: 'UTC', // Set the timeZone option to UTC
            };

            const formattedDate = new Date(dateString).toLocaleString("en-ZA", options);

            return formattedDate;
        },
        async beginArticleRegen(item) {
            // console.log({item})
            this.articleBody = item.Body
            this.resourceCitations = [];
            for (let citation of item.Citations) {
                // console.log({
                //     citation
                // })
                this.resourceCitations.push({
                    sourceURL: citation["Source URL"],
                    editing: false
                });
            }
            // console.log({
            //     item
            // })
            let author = await this.$API.getAuthor({
                journalist: item.Author
            })
            // console.log(author)
            this.selectedAuthor = author
            this.regenArticleDialog = true
            this.articleDialog = false
        },
        async regenImage() {
            this.regenImageLoad = true
            // console.log(this.selectedArticle)
            let response = await this.$API.regenImage({
                Image: this.selectedArticle.Image,
                fileName: this.selectedArticle.fileName
            })
            this.previewImage = response;
            this.regenImageLoad = false
            this.updateKey++
        },
        async replaceImage() {
            // console.log(this.selectedArticle)
            let response = await this.$API.replaceImage({
                fileName: this.selectedArticle.fileName
            })
            // console.log(response)
            this.imageDialog = false
            this.allArticles();
            this.updateKey++
        },
        async createArticle() {
            this.articleGenLoad = true
            let body = {
                author: this.selectedAuthor,
                article: this.articleBody,
                sourceURLs: this.resourceCitations
            }
            let response = await this.$API.generateManualArticle(body)
            this.allArticles()
            this.createArticleDialog = false
            this.updateKey++
            this.articleGenLoad = false
        },
        async regenArticle() {
            this.regenArticleLoad = true;
            let body = {
                author: this.selectedAuthor,
                article: this.articleBody,
                sourceURLs: this.resourceCitations,
                filename: this.selectedArticle.fileName
            }

            let response = await this.$API.regenerateArticle(body);
            this.allArticles()
            this.regenArticleDialog = false
            this.updateKey++
            this.regenArticleLoad = false;
        },
        async setPublishStatus(item) {
            let response = await this.$API.setPublishStatus(item)
            this.allArticles()
            this.updateKey++
        },
        toggleEdit(index) {
            this.resourceCitations[index].editing = !this.resourceCitations[index].editing;
        },
        saveEdit(index) {
            // Save the edited item
            this.resourceCitations[index].editing = false;
        },
        deleteItem(index) {
            // Delete the item at the specified index
            this.resourceCitations.splice(index, 1);
        },
        addItem() {
            // Add a new item to the list
            this.resourceCitations.push({
                sourceURL: '',
                editing: true
            });
        },
        markdownToHtml(markdown) {
            return markdown.split('\n').map(line => {
                if (line.startsWith('### ')) {
                    return `<h4>${line.slice(4)}</h4>`;
                } else if (line.startsWith('## ')) {
                    return `\n\n<h3>${line.slice(3)}</h3>`;
                } else if (line.startsWith('# ')) {
                    return ``;
                } else {
                    return line;
                }
            }).join('');
        },
        async addAuthor() {
            let response = await this.$API.addAuthor(this.newAuthor);
            this.createAuthorDialog = false
            this.newAuthor = {}
            this.fetchAuthors()
            this.snackbar.show = true
            this.snackbar.text = response.message
            this.snackbar.color = "success"
        },
        startAuthorEdit(item) {
            this.originalAuthor = {
                ...item
            }; // Save the original data
            this.editAuthor = {
                ...item,
                newRegions: []
            }; // Use a separate object for editing
            this.editAuthorDialog = true;
        },
        cancelAuthorEdit() {
            this.editAuthor = {}; // Reset the editAuthor object
            this.editAuthorDialog = false;
        },
        async saveAuthorEdit() {
            this.editAuthor.regions = this.editAuthor.newRegions
            delete this.editAuthor.newRegions;
            let response = await this.$API.editAuthor(this.editAuthor)
            this.editAuthorDialog = false;
            this.fetchAuthors()
            this.snackbar.show = true
            this.snackbar.text = response.message
            this.snackbar.color = "success"
            this.updateKey++
            this.editAuthor = {};
        },
        async startAuthorDeletion(item) {
            this.authorToDelete = item;
            this.deleteAuthorDialog = true;
        },
        async readLog(item) {
            console.log(item);

            this.logJSON = await this.$API.readLog(item)

            this.readLogDialog = true;
        },
        async onRegionChange(item) {
            let response = await this.$API.changeRegion({
                id: item.id,
                region: item.region
            })
        },
        async deleteAuthor() {
            let deleteResponse = await this.$API.deleteAuthor(this.authorToDelete);
            this.deleteAuthorDialog = false;

            this.authors = this.articles.filter(author => author !== this.authorToDelete);
            this.fetchAuthors()
            this.snackbar.show = true
            this.snackbar.text = deleteResponse.message
            this.snackbar.color = "success"
            this.updateKey++
        },
        async allArticles() {
            this.articleLoading = true
            if (this.articleSearch !== "") {
                this.articleParams.search = this.articleSearch;
            } else {
                delete this.articleParams.search
            }
            // console.log({articleParams: this.articleParams})
            let response = await this.$API.allArticles(this.articleParams);
            this.articles = response.articles
            this.articleTotal = response.totalArticles
            // console.log({response})
            this.articleLoading = false
        },
        async fetchAuthors() {
            this.authorsLoading = true
            if (this.authorSearch !== "") {
                this.authorParams.search = this.authorSearch;
            } else {
                delete this.authorParams.search
            }
            let response = await this.$API.getAuthors(this.authorParams);
            this.authors = response.authors;
            this.authorTotal = response.total;
            this.authorsLoading = false
        },
        async readArticle(item) {
            // console.log({item})
            this.selectedArticle = await this.$API.readArticle({
                fileName: item.fileName
            })
            this.selectedArticle.smallImage = item.smallImage
            this.selectedArticle.mediumImage = item.mediumImage
            this.selectedArticle.fileName = item.fileName
            this.selectedArticle.createdAt = item.createdAt

            // console.log({selectedArticle: this.selectedArticle})

            this.articleDialog = true
        },
        async fetchLogs() {
            this.logsLoading = true
            if (this.logsSearch !== "") {
                this.logsParams.search = this.logsSearch;
            } else {
                delete this.logsParams.search
            }
            let response = await this.$API.fetchLogs(this.logsParams)
            this.logs = response.paginatedFileList;
            this.logsTotal = response.total
            this.logsLoading = false
        }
    },
    created() {
        // this.allArticles()
        this.fetchAuthors()
        this.fetchLogs()
    },
    mounted() {
        if (window.location.hash) {
            let component = decodeURI(window.location.hash).split('#')[1];
            this.activeTab = component
        } else {
            this.$router.push({
                hash: '#news/articles'
            })
        }
    },
}
</script>

<style></style>
