<template>
<div style="background: var(--v-component-base) !important">
    <v-row align="end" justify="end" class="mb-2">
        <v-col cols="12" sm="12" md="6" lg="4">
            <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search">
                <template slot="prepend">
                    <el-button :key="refreshKey" @click="createGuide()" el-tooltip="Create Guide">
                        <v-icon>add</v-icon>
                    </el-button>
                </template>
            </el-input>
        </v-col>
    </v-row>

    <v-data-table :key="refreshKey" dense fixed-header hide-default-footer :loading="loading" :items="guides.data" :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" height="65vh" style="cursor: pointer" :options="{
              page: page,
              itemsPerPage: params.limit,
              pageStart: 1,
              pageStop: Math.round(guides.total / params.limit),
              pageCount: Math.round(guides.total / params.limit),
              itemsLength: guides.total,
            }">

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="editGuide(item)">
                <v-icon >edit</v-icon>
            </v-btn>
            <v-btn icon @click="deleteGuide(item)">
                <v-icon color="redPop">delete</v-icon>
            </v-btn>
        </template>
        <template v-slot:[`item.updatedBy`]="{ item }">
            {{ item.updatedBy.firstname }} {{ item.updatedBy.surname }}
        </template>
        <template v-slot:[`item.createdBy`]="{ item }">
            {{ item.createdBy.firstname }}  {{ item.createdBy.surname }}
        </template>
    </v-data-table>

    <v-dialog v-model="createDialog" class="black-dialog" width="800px" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Create Guide</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="saveGuide()">
                    <v-icon color="primary">save</v-icon>
                </v-btn>
                <v-btn icon @click="createDialog = false">
                    <v-icon color="redPop">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row no-gutters class="pa-2 py-2">
                    <v-col cols="12" sm="5" md="5" class="py-0 px-1">
                        <v-text-field v-model="selectedGuide.name" outlined hide-details dense label="Name"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="7" md="7" class="py-0 px-1">
                        <v-text-field v-model="selectedGuide.url" outlined hide-details dense label="URL"></v-text-field>
                    </v-col>
                </v-row>
                <v-row  no-gutters class="pa-2 py-2">
                    <v-textarea v-model="selectedGuide.code" outlined dense hide-details label="Code"></v-textarea>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" class="black-dialog" width="400px" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Delete Guide: {{ this.selectedGuide.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="confirmDeletion()">
                    <v-icon color="success">check</v-icon>
                </v-btn>
                <v-btn icon @click="deleteDialog = false">
                    <v-icon color="redPop">close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                Are you sure you wish to delete this guide?
            </v-card-text>
        </v-card>
    </v-dialog>

<v-dialog v-model="editDialog" class="black-dialog" width="800px" persistent>
    <v-card>
        <v-toolbar flat>
            <v-toolbar-title>Edit Guide: {{ this.selectedGuide.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="updateGuide()">
                <v-icon color="success">check</v-icon>
            </v-btn>
            <v-btn icon @click="editDialog = false">
                <v-icon color="redPop">close</v-icon>
            </v-btn>
        </v-toolbar>
            <v-card-text>
                <v-row no-gutters class="pa-2 py-2">
                    <v-col cols="12" sm="5" md="5" class="py-0 px-1">
                        <v-text-field v-model="selectedGuide.name" outlined hide-details dense label="Name"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="7" md="7" class="py-0 px-1">
                        <v-text-field v-model="selectedGuide.url" outlined hide-details dense label="URL"></v-text-field>
                    </v-col>
                </v-row>
                <v-row  no-gutters class="pa-2 py-2">
                    <v-textarea v-model="selectedGuide.code" outlined dense hide-details label="Code"></v-textarea>
                </v-row>
            </v-card-text>
    </v-card>
</v-dialog>
</div>
</template>

<script>
export default {
    data: () => ({
        refreshKey: 0,
        selectedGuide: {

        },
        guides: {
            total: 0,
            data: [],
        },
        headers: [{
                text: "Actions",
                value: "actions",
                align: "center",
            },
            {
                text: "Name",
                value: "name",
                align: "center",
            },
            {
                text: "URL",
                value: "url",
                align: "center",
            },
            {
                text: "Created By",
                value: "createdBy",
                align: "center",
            },
            {
                text: "Updated By",
                value: "updatedBy",
                align: "center",
            },
        ],
        loading: false,
        page: 1,
        sortBy: 'name',
        sortDesc: false,
        params: {
            limit: 18,
            offset: 0,
        },
        searchTimer: null,
        createDialog: false,
        editDialog: false,
        deleteDialog: false
    }),
    watch: {
        sortBy(value) {
            this.params.sortBy = value
            this.getGuides()
        },
        sortDesc(value) {
            this.params.sortDesc = value
            this.getGuides()
        },
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit;
            await this.getGuides();
        },
        "params.limit": {
            immediate: true,
            async handler() {
                this.page = 1;
                await this.getGuides();
            },
        },
        "params.search": {
            async handler() {
                await this.getGuides();
            },
        },
    },
    mounted() {
        // this.focusOnSearch()
        this.$refs.searchBar.focus()
    },
    created() {
        this.getGuides();
    },
    methods: {
        async getGuides() {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
            }
            this.loading = true;
            this.searchTimer = setTimeout(async () => {
                this.guides = await this.$API.getGuides({
                    params: this.params,
                });
                this.loading = false;
                this.refreshKey++
            }, 300);
        },
        async createGuide() {
            this.selectedGuide = {};
            this.createDialog = true;
        },
        async saveGuide() {
            await this.$API.createGuide(this.selectedGuide)
            this.getGuides()
        },
        async deleteGuide(item) {
            this.selectedGuide = item
            this.deleteDialog = true
        },
        async confirmDeletion() {
            await this.$API.deleteGuide(this.selectedGuide)
            this.deleteDialog = false
            this.getGuides()
        },
        async editGuide(item) {
            this.selectedGuide = item
            this.editDialog = true
        },
        async updateGuide() {
            await this.$API.updateGuide(this.selectedGuide)
            this.editDialog = false
            this.getGuides()
        }
    },
};
</script>
