<template>
  <div style="background: var(--v-component-base) !important">
    <v-tabs v-model="reportBuilderTab">
      <v-tab href="#manager" @click="activeTab != 'reports/manager' ? $router.push({ hash: '#reports/manager' }) : ''">Report Manager</v-tab>
      <v-tab href="#builder" @click="activeTab != 'reports/builder' ? $router.push({ hash: '#reports/builder' }) : ''">Report Builder</v-tab>
    </v-tabs>
    <v-tabs-items v-model="reportBuilderTab" class="my-0 py-0" style="background: var(--v-component-base) !important;">
      <v-tab-item value="manager">
        {{templates}}
      </v-tab-item>
      <v-tab-item value="builder">
        <div style="width: 100%; height: 78vh; overflow: hidden">
          <iframe
            src="https://reportwriter.loglive.ai/"
            frameborder="0"
            style="width: 100%; height: 100%; border: none"
          ></iframe>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reportBuilderTab: "manager",
      activeTab: "manager",
      templates: [],
    };
  },
  mounted() {
    this.setUrl()
    this.listReportTemplates()
  },
  methods: {
    async listReportTemplates(){
      let response = await this.$API.listReportTemplates()
      console.log(response);
      this.templates = response.data
    },
    setUrl(){
      if (window.location.hash) {
        let component = decodeURI(window.location.hash).split('#')[1];
        this.activeTab = component
      } else {
        this.$router.push({
          hash: '#reports/manager'
        })
      }
    }
  },
};
</script>
